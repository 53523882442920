export default [
  {
    id: 1,
    quantity: 1,
    name: "Доска обрезная из сосны",
    size: "50х150х6000",
    price: "750",
    img: '26',
  },
  {
    id: 2,
    quantity: 1,
    name: "Доска обрезная из сосны",
    size: "50х100х6000",
    price: "492",
    img: '28',
  },
  {
    id: 3,
    quantity: 1,
    name: "Доска обрезная из сосны",
    size: "40х150х6000",
    price: "606",
    img: '27',
  },
  {
    id: 4,
    quantity: 1,
    name: "Доска обрезная из сосны",
    size: "25х150х6000",
    price: "363",
    img: '28',
  },
  {
    id: 5,
    quantity: 1,
    name: "Доска обрезная из сосны",
    size: "25х100х6000",
    price: "235",
    img: '28',
  },
  {
    id: 6,
    quantity: 1,
    name: "Фанера фк 8мм берёза",
    size: "1525х1525",
    price: "520",
    img: '24',
  },
  {
    id: 7,
    quantity: 1,
    name: "Фанера фк 10мм берёза",
    size: "1525х1525",
    price: "580",
    img: '24',
  },
  {
    id: 8,
    quantity: 1,
    name: "Фанера фк 12мм берёза",
    size: "1525х1525",
    price: "660",
    img: '25',
  },
  {
    id: 9,
    quantity: 1,
    name: "ГКЛ влагостойкий 9.5",
    size: "2500х1200",
    price: "460",
    img: '21',
  },
  {
    id: 99,
    quantity: 1,
    name: "ГКЛ влагостойкий 12.5",
    size: "2500х1200",
    price: "495",
    img: '21',
  },
  {
    id: 10,
    quantity: 1,
    name: "Профиль потолочный",
    size: "60/27.    0.45мм",
    price: "160",
    img: '35',
    sht: '1шт ',
  },
  {
    id: 11,
    quantity: 1,
    name: "Профиль",
    size: "27/28     0.45мм",
    price: "100",
    img: '34',
    sht: '1шт ',
  },
  {
    id: 12,
    quantity: 1,
    name: "Профиль стоечный",
    size: "50/50     0.45мм",
    price: "225",
    img: '36',
    sht: '1шт ',
  },
  {
    id: 13,
    quantity: 1,
    name: "Профиль направляющий",
    size: "50/40     0.45мм",
    price: "185",
    img: '37',
    sht: '1шт ',
  },
  {
    id: 14,
    quantity: 1,
    name: "Плита OSB 9mm",
    size: "1.22x2.44",
    price: "500",
    img: '22',
    sht: '1шт ',
  },
  {
    id: 15,
    quantity: 1,
    name: "Плита OSB 12мм ",
    size: "1.22х2.44 ",
    price: "600",
    img: '23',
    sht: '1шт ',
  },
  {
    id: 16,
    quantity: 1,
    name: "Клей цементный Ceresit CM-11pro",
    size: "25кг",
    price: "495",
    img: '19',
  },
  {
    id: 17,
    quantity: 1,
    name: "Клей цементный Ceresit CM-14 Exstra",
    size: "25кг",
    price: "745",
    img: '18',
  },
  {
    id: 18,
    quantity: 1,
    name: "Клей цементный Волма-Интерьер ",
    size: "25кг",
    price: "320",
    img: '30',
  },
  {
    id: 19,
    quantity: 1,
    name: "Пеноплекс Комфорт 20мм",
    size: "585х1185",
    price: "140",
    img: '14',
    sht: '1шт ',
  },
  {
    id: 20,
    quantity: 1,
    name: "Пеноплекс Комфорт 30мм",
    size: "585х1185",
    price: "200",
    img: '14',
    sht: '1шт ',
  },
  {
    id: 21,
    quantity: 1,
    name: "Пеноплекс Комфорт 50мм",
    size: "585х1185",
    price: "320",
    img: '15',
    sht: '1шт ',
  },
  {
    id: 22,
    quantity: 1,
    name: "Утеплитель Роклайт 5.76м2 8плит",
    size: "50ммх600х1200",
    price: "1115",
    img: '29',
  },
  {
    id: 23,
    quantity: 1,
    name: "Утеплитель технофас Эффект 4.33м2",
    size: "50ммх600х1200",
    price: "2970",
    img: '11',
  },
  {
    id: 24,
    quantity: 1,
    name: "Утеплитель ISOVER плита 5м2",
    size: "100х610х1170",
    price: "1380",
    img: '5',
  },
  {
    id: 25,
    quantity: 1,
    name: "ГКЛ обычный 9.5",
    size: "2500х1200",
    price: "390",
    img: '20',
  },
  {
    id: 26,
    quantity: 1,
    name: "ГКЛ обычный 12.5",
    size: "2500х1200",
    price: "420",
    img: '20',
  },
  {
    id: 27,
    quantity: 1,
    name: "Шпаклевка Волма Сатин",
    size: "20кг",
    price: "365",
    img: '16',
  },
  {
    id: 28,
    quantity: 1,
    name: "Шпаклевка Волма Финиш ",
    size: "20кг",
    price: "515",
    img: '17',
  },
  {
    id: 29,
    quantity: 1,
    name: "Штукатурка Волма Аквапласт ",
    size: "25кг",
    price: "395",
    img: '33',
  },
  {
    id: 30,
    quantity: 1,
    name: "Смесь цементно-песчанная Сухов М300",
    size: "25кг",
    price: "300",
    img: '2',
  },
  {
    id: 31,
    quantity: 1,
    name: "Смесь цементно-песчанная Сухов М150",
    size: "25кг",
    price: "270",
    img: '1',
  },
  {
    id: 32,
    quantity: 1,
    name: "Цемент ССПЦ 500Д-20",
    size: "25кг",
    price: "280",
    img: '4',
  },
  {
    id: 33,
    quantity: 1,
    name: "Блок г/б Д500 (128шт/под) ",
    size: "100х625х250",
    price: "113",
    img: '8',
    sht: '1шт ',
  },
  {
    id: 34,
    quantity: 1,
    name: "Блок г/б Д500 (96шт/под)",
    size: "125х625х250",
    price: "142",
    img: '8',
    sht: '1шт ',
  },
  {
    id: 35,
    quantity: 1,
    name: "Блок г/б Д500 (80шт/под)",
    size: "150х625х250",
    price: "174",
    img: '9',
    sht: '1шт ',
  },
  {
    id: 36,
    quantity: 1,
    name: "Блок г/б Д500 (64шт/под) ",
    size: "200х625х250",
    price: "227",
    img: '10',
    sht: '1шт ',
  },
  {
    id: 37,
    quantity: 1,
    name: "Блок г/б Д50 (160шт/под)",
    size: "80х625х250",
    price: "91",
    img: '8',
    sht: '1шт ',
  },
  {
    id: 38,
    quantity: 1,
    name: "Сетка дорожная Р ТУ",
    size: "50х50х3 (2.0х3.0м) 1/50",
    price: "865",
    img: '7',
    sht: '1шт ',
  },
  {
    id: 39,
    quantity: 1,
    name: "Сетка дорожная ТУ",
    size: "100х100х4мм 6м2 (2.0х3.0)",
    price: "800",
    img: '7',
    sht: '1шт ',
  },
  {
    id: 40,
    quantity: 1,
    name: "Сетка дорожная ТУ",
    size: "150х150х4мм (2.0х3.0) 1/50",
    price: "565",
    img: '7',
    sht: '1шт ',
  },
  {
    id: 41,
    quantity: 1,
    name: "Евро вагонка 12.5*96*3000мм оптима АВ ель/сосна",
    size: "1уп=10шт 2.88м2",
    price: "1630",
    sht: '1уп',
    img: '31',
  },
  {
    id: 42,
    quantity: 1,
    name: "Брус",
    size: "100х100",
    price: "1040",
    img: '32',
  }
]