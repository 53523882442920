import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Input from '@mui/joy/Input';
import FormLabel from '@mui/joy/FormLabel';
import axios from 'axios';
import Modal from '@mui/joy/Modal';

import './SendOrder.css';

const FinalForm = ({data, basketList, onChangeBasketList}) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleOrder = async (event) => {
    event.preventDefault();

    let productText = '';
    const hash = window.location.hash;

    let start = hash.indexOf("?") + 1;
    let itemId = hash.slice(start);

    if(start === 0) {
      if(basketList?.length) {
        basketList.forEach(item => {
          productText += `
          ${item.name}, ${item.size}, количество: ${item.quantity}, ${item.price} руб.`
        });
      }
    } else {
      if(basketList?.length) {
        const chosenItem =  data.filter(item => item.id == itemId);

        if(chosenItem?.length) {
          productText += `
          ${chosenItem[0].name}, ${chosenItem[0].size}, количество: ${chosenItem[0].quantity}, ${chosenItem[0].price} руб.`
        }

      }
    }

    const response = await axios.post(
      'https://api.telegram.org/bot6081875688:AAFqTboXsE3ZdG77Bxmg_0aT60BWoxLVJbw/sendMessage',
      {
        chat_id: 1379561596,
        text: ` -----
          Email: ${email},
          Телефон: ${phone},
          Имя: ${name},
          Адрес: ${address}
          -----
          ${productText}
          `,
      }
    ).then(() => {
      if(start === 0) {
        onChangeBasketList([])
      }
      setOpen(true)
    });
  }

  return (
    <div className='order-form'>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <div className='modal-confirmation'>
          <div className='grey center-a'>
            Cпасибо за ваш заказ, дождитесь пожалуйста пока с вами свяжется администратор
          </div>
          <Link to='/katalog' className='btn-light-green'>
            Продолжать
          </Link>
        </div>
      </Modal>
      <div className='red'>Оплата производится на ООО «Лесоторговая база строй материалы»</div>
      <div className='grey'>Цены меняются переодически  уточняйте у продавца</div>
      <div className='section-header'>Оформление заказа</div>
      <div className='input-row'>
        <FormLabel>Email</FormLabel>
        <Input 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined" 
          color="primary" 
          className='mt-10'
        />
      </div>
      <div className='input-row'>
        <FormLabel>Телефон <span className='red'>*</span></FormLabel>
        <Input 
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          variant="outlined" 
          color="primary" 
          />
      </div>
      <div className='input-row'>
        <FormLabel>Имя <span className='red'>*</span></FormLabel>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined" 
          color="primary" 
          className='mt-10'
        />
      </div>
      <div className='input-row'>
        <FormLabel>Адрес</FormLabel>
        <Input
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          variant="outlined"
          color="primary"
          className='mt-10'
        />
      </div>
      <div
        className={name && phone ? 'btn-light-green' : 'btn-light-grey'}
        onClick={(e) => name && phone ? handleOrder(e) : null}
      >Оформить заказ</div>
    </div>
  );
}

function index({data, basketList, onChangeBasketList}) {
  return <FinalForm 
    data={data}
    basketList={basketList}
    onChangeBasketList={onChangeBasketList}
  />
}

export default index;