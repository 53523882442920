import React from 'react';

function index(props) {
  return (
    <div>
      About us
    </div>
  );
}

export default index;