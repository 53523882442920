import React, { useState } from 'react';
import {  HashRouter, Link } from 'react-router-dom';

import './Navigation.css';

function Navigation({}) {
  const [activeTab, setActiveTab] = useState('');

  return (
    <div className='bottom-header'>
      <ul className='bottom-header-ul'>
        <HashRouter>
          <li className='green-bg'>
            <Link to='/katalog'
            onClick={() => setActiveTab('katalog')}
            >
              <i className="material-icons mr-10">&#xe5d2;</i>
              Каталог
            </Link>
          </li>
          <li>
            <Link to='payment'
              onClick={() => setActiveTab('payment')}
            >
              Оплата
            </Link>
          </li>
          <li>
            <Link to='delivery'
              onClick={() => setActiveTab('delivery')}
            >
              Доставка
            </Link>
          </li>
          <li>
            <Link to='contacts'
              onClick={() => setActiveTab('contacts')}
            >
              Контакты
            </Link>
          </li>
        </HashRouter>
      </ul>
    </div>
  );
}

export default Navigation;