import React, { useState } from 'react';

import BasketList from '../BasketList';

import './Header.css';

const Header = ({basketList, onChangeBasketList}) => {
  const [address, setAddress] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [basketListDropdown, setBasketListDropdown] = useState(false);


  return (
    <div className='Header'>
      <div className='info-header'>
        <div className='address-info' onClick={() => setAddress(!address)}>
          <div className='address-info-dropUp'>
            <i className="material-icons mr-10">&#xe55e;</i>
            Наш адрес
            {address ? <i className="material-icons">&#xe5ce;</i> : <i className="material-icons">&#xe5cf;</i>}
          </div>
        </div>
        <div className='schedule-info' onClick={() => setSchedule(!schedule)}>
          <div className='schedule-info-dropUp'>
            <i className="material-icons mr-10">&#xe8b5;</i>
            Время работы
            {schedule ? <i className="material-icons">&#xe5ce;</i> : <i className="material-icons">&#xe5cf;</i>}
          </div>
        </div>
      </div>
      {/* address */}
      {address ? <div className='info-dropDown'>
        Г Сочи Адлер улица урожайная 9
      </div> : null}
      {/* schedule */}
      {schedule ? <div className='info-dropDown'>
      с 9:00 до 19:00 Без выходных
      </div> : null}
      <div className='baner'>
        <div className='col'>
          <div className='company-name bold'>Лесоторговая база строй материалы</div>
          <div className='small'>Продажа пиломатериалов</div>
        </div>
        <div className='col'>
          <div className='bold'>+7 (965) 467-76-90</div>
          <div><a href="tel:79654677690" className='green small'>Заказать звонок</a></div>
        </div>
        <div className='col basket'>
          {basketListDropdown ? <BasketList
            onChangeBasketList={onChangeBasketList}
            basketList={basketList}
            onClose={() => setBasketListDropdown(false)}
          /> : null}
          <div onClick={() => basketList?.length ? setBasketListDropdown(!basketListDropdown) : null}>
            <img src={require('../../img/cart-icon.svg').default} alt='mySvgImage' />
            {basketList?.length ? <div className='basket-number'>{basketList.length}</div> : null}
          </div>
          {/* <div className='bold small'>0 руб.</div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;