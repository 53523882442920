import React from 'react';

function index(props) {
  return (
    <div className='section'>
      <div className='section-header'>Доставка пиломатериалов</div>
      <div className='section-w'>
        <div className='text-s'>Интернет-магазин «Лесоторговая база строй материалы» предлагает широкий ассортимент экологичных стройматериалов из дерева. Вы можете забрать заказ самостоятельно со склада либо заказать доставку по адресу. Стоимость при этом рассчитывается в индивидуальном порядке и зависит от расстояния до объекта строительства, объема пиломатериалов, вида автомобиля.</div>
        <div className='text-s'>Для согласования сроков, места и суммы доставки обращайтесь к менеджеру. Наш сотрудник рассчитает объем досок, бруса, вагонки либо иного материала и озвучит вам точную цену покупки с дополнительными услугами.</div>
        <h1>Условия доставки</h1>
        <div>Доставляем пиломатериалы:</div>
        <ul className='general-list'>
          <li>в центр Адлер;</li>
          <li>в любой другой район;</li>
        </ul>
      </div>
    </div>
  );
}

export default index;