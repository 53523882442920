import React from 'react';

import './Payment.css';

function index(props) {
  return (
    <div className='section'>
      <div className='section-header'>Оплата</div>
      <div className='section-w'>
        <div>В компании «Лесоторговая база строй материалы» предусмотрены удобные способы оплаты для физических, юридических лиц и индивидуальных предпринимателей:</div>
        <ul className='general-list'>
          <li>наличными – при самовывозе;</li>
          <li>банковской картой – персональной или корпоративной;</li>
          <li>безналичным переводом – по договору на расчетный счет.</li>
        </ul>
        <div>В нашей компании действуют скидки, которые зависят от количества заказанных пиломатериалов. Перед оплатой свяжитесь с нашим менеджером, чтобы сделать точный расчет и узнать итоговую стоимость. погрузка осуществляется бесплатно.</div>
        <h1>Почему с нами выгодно</h1>
        <div>«Лесоторговая база строй материалы» – производитель и надежный поставщик всех видов пиломатериалов. Мы предлагаем:</div>
        <ul className='general-list'>
          <li>гарантию качества на всю продукцию;</li>
          <li>выгодные цены от производителя;</li>
          <li>доставку в течение одного дня;</li>
          <li>любые варианты оплаты на ваш выбор.</li>
        </ul>
      </div>
    </div>
  );
}

export default index;