import React, { useState } from 'react';
import { HashRouter, Switch, Route } from "react-router-dom";
import data from './routes/Katalog/list.js';

import Header from './components/Header';
import Navigation from './components/Navigation';

// routes
import Katalog from './routes/Katalog';
import SendOrder from './routes/SendOrder';
import AboutUs from './routes/AboutUs';
import Payment from './routes/Payment';
import Delivery from './routes/Delivery';
import Contacts from './routes/Contacts';
// style
import './App.css';

function App() {
  const [basketList, setBasketList] = useState(JSON.parse(localStorage.getItem('basketList')) || [])
  
  const handleBasketList = (list) => {
    localStorage.setItem('basketList', JSON.stringify(list));
    setBasketList(list);
  }

  return (
    <div className="main-app">
      <HashRouter>
      <div className='app-navigation'>
        <Header 
          basketList={basketList}
          onChangeBasketList={handleBasketList}
        />
        <Navigation />
      </div>
          <Switch>
            <Route  exact path="/katalog">
              <Katalog
                data={data}
                onChangeBasketList={handleBasketList}
                basketList={basketList}
              />
            </Route>
          </Switch>
          <Switch>
            <Route exact path="/payment">
              <Payment />
            </Route>
          </Switch>
          <Switch>
            <Route exact path="/delivery">
              <Delivery />
            </Route>
          </Switch>
          <Switch>
            <Route exact path="/contacts">
              <Contacts />
            </Route>
          </Switch>
          <Switch>
            <Route exact path="/aboutUs">
              <AboutUs />
            </Route>
          </Switch>  
          <Switch>
            <Route  exact path="/sendOrder">
              <SendOrder
                data={data}
                basketList={basketList}
                onChangeBasketList={handleBasketList}
              />
            </Route>
          </Switch>
          <Switch>
            <Route  exact path="/">
              <Katalog 
                data={data}
                onChangeBasketList={handleBasketList}
                basketList={basketList}
              />
            </Route>
          </Switch>
        </HashRouter>
      </div>
  );
}

export default App;
