import React from 'react';

function index(props) {
  return (
    <div className='section'>
      <div className='section-header'>Контакты</div>
      <div className='section-w'>
        <h1>Контактная информация</h1>
        <div className='row flex'>
          <div className='grey mr-10'>Телефон:</div>
          <div><a href="tel:79654677690" className='green'>+7 (965) 467-76-90</a></div>
        </div>
        <div className='row flex'>
          <div className='grey mr-10'>E-mail:</div>
          <div className='green'><a href = "mailto: egiazaryan_75@mail.ru" className='green'>egiazaryan_75@mail.ru</a></div>
        </div>
        <div className='grey text-s'>Время работы: с 9:00 до 18:00, Сб-Вс выходные.</div>
        <div className='grey text-s'>Г Сочи Адлер улица урожайная 9</div>
      </div>
    </div>
  );
}

export default index;