import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Katalog.css';


const Card = ({data, onChangeBasketList, basketList}) => {
  const [type, setType] = useState(1) // 1 === за штуку, 2 === за 1 куб


  const handleBasket = (item) => {
    if(basketList?.length) {
      onChangeBasketList([...basketList, {...item, quantity: item.quantity}]);
    } else {
      onChangeBasketList([{...item, quantity: item.quantity}])
    }
  }

  const handleDeleteBasket = (_item) => {
    if(basketList?.length) {
      onChangeBasketList(basketList.filter(item => item.id !== _item.id))
    } 
  }

  const handleQuantity = (_item, type) => {
    if(type === '-' && _item.quantity > 1) {
      onChangeBasketList(basketList.map(item => item.id === _item.id
        ? ({...item, quantity: item.quantity-1})
        : item))
    }
    if (type === '+' && _item.quantity < 100) {
      onChangeBasketList(basketList.map(item => item.id === _item.id
        ? ({...item, quantity: item.quantity+1})
        : item))
    }
  }

  return(<div className='Card'>
    <div className='Card-img'>
      {data.img && <img src={require(`../../img/${data.img}.jpeg`)} alt='mySvgImage' width='100%'/>}
    </div>
    <div className='Card-info green'>{data?.name} {data?.size}</div>
    <div className='Card-price'>
      <div className='bold mr-10'>Цена:</div>
      <div className='align-left'>
        <div className='dark-green bold'>{data?.sht} {data?.price || 0} руб.</div>
      </div>
    </div>
    <div className='center'>
      <div onClick={() => {setType(1)}} className={`switcher ${type === 1 && 'active'} `}>Шт.</div>
      <div onClick={() => {setType(2)}} className={`switcher ${type === 2 && 'active'}`} >Куб</div>
    </div>
    {basketList.filter(item => item.id === data.id).length 
      ? <div className='Card-counter center'>
          <div
            onClick={() => handleQuantity(data, '-')}
            className='counter center'
          >-</div>
          {data.quantity}
          <div
            onClick={() => handleQuantity(data, '+')}
            className='counter center'
          >+</div>
        </div>
      : <div className='Card-counter center'></div>
    }
    
    {basketList.filter(item => item.id === data.id).length ? <div 
      className='btn-dark-red'
      onClick={() => handleDeleteBasket(data)}
      >Удалить</div>
    : <div 
      className='btn-dark-grey' 
      onClick={() => handleBasket(data)}
      >В корзину </div>}
      <Link to={`/sendOrder?${data.id}`} className='link-reset'>
        <div className='btn-light-grey'>Купить в 1 клик</div>
      </Link>
  </div>)
}

const index = ({data, onChangeBasketList, basketList}) => {

  return (
    <div className='center-a'>
      {data?.length ? data.map(item => {
        const chosenItems = basketList.filter(basketItem => basketItem.id === item.id)
        let chosenItem = {};
        if(chosenItems.length){
          chosenItem = chosenItems[0]
        }

        return (
          <Card 
            data={{...item, ...chosenItem}} 
            key={item.id}
            onChangeBasketList={onChangeBasketList}
            basketList={basketList}
        />
        )
      }) : null}
    </div>
  );
}

export default index;