import React from 'react';
import { Link } from 'react-router-dom';

import './BasketList.css';

function index({basketList, onChangeBasketList, onClose}) {

  const handleQuantity = (_item, type) => {
    if(type === '-' && _item.quantity > 1) {
      onChangeBasketList(basketList.map(item => item.id === _item.id
        ? ({...item, quantity: item.quantity-1})
        : item))
    }
    if (type === '+' && _item.quantity < 100) {
      onChangeBasketList(basketList.map(item => item.id === _item.id
        ? ({...item, quantity: item.quantity+1})
        : item))
    }
  }

  const handleDeleteBasket = (_item) => {
    if(basketList?.length) {
      onChangeBasketList(basketList.filter(item => item.id !== _item.id))
    } 
  }

  const totalPrice = () => {
    return basketList.reduce((accumulator, currentValue) =>  Number(accumulator) + (Number(currentValue.price) * Number(currentValue.quantity)), 0);
  }

  return (
    <ul className='basket-list'>
      {basketList?.length ? basketList.map(item => <li>
        <div className='basket-list-delete' onClick={() => handleDeleteBasket(item)}>
        <i className="material-icons mr-10">&#xe872;</i>
        </div>
        <div className='flex center'>{item.name}</div>
        <div className='flex center'>{item.size}</div>
        <div className='flex center green'>{item.price} руб.</div>
        <div className='Card-counter center'>
          <div
            onClick={() => handleQuantity(item, '-')}
            className='counter center'
          >-</div>
          {item.quantity}
          <div
            onClick={() => handleQuantity(item, '+')}
            className='counter center'
          >+</div>
        </div>
      </li>) : null}
      <div className='Card-price mt-20'>
        <div className='bold mr-10'>суммуа:</div>
        <div className='align-left'>
          <div className='dark-green bold'>{totalPrice()} руб.</div>
        </div>
      </div>
      <Link to='/sendOrder'
        onClick={onClose}
      >
          <div className='btn-light-green'>Заказать</div>
      </Link>
    </ul>
  );
}

export default index;